.gallery {
display: flex;
justify-content: space-around;
align-items: center;
    padding: 25px;
width: 75%;
}

.gallery .image-gallery {
    width: 75%;
}

@media (max-width: 1190px) {
    img {
        width: 100%
    }
    h2 {
        text-align: center;
    }
    .large_paragraph {
        font-size: 1.2rem;
        padding: 10px;
    }
    .small_paragraph {
        
        padding: 10px;
    }
}
